<template>
    <router-view></router-view>
</template>

<script>
export default {
    name: 'Withdraw',
    data () {
        return {
            type: -1,
            showPage: false
        }
    },
    created () {
        this.type = this.$store.state.member.memberCenter.status
        if (this.type === 4 || this.type === 5 || this.type === 6) { // 待开户 | 开户失败
            this.$router.push('/user/account/balance/open-account')
        }
    }
}
</script>
